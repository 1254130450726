<template>
    <el-dialog title="Leave a message" :visible.sync="dialogVisible" :width="width" :before-close="onHandleClose"
        :custom-class="'apply-membership-dialog'">
        <div>
            <FormBoxVue :title="'First Name'" :isRequired="true">
                <template #content>
                    <el-input v-model="formData.firstName" placeholder=""></el-input>
                </template>
            </FormBoxVue>

            <FormBoxVue :title="'Last name'" :isRequired="true">
                <template #content>
                    <el-input v-model="formData.lastName" placeholder=""></el-input>
                </template>
            </FormBoxVue>

            <FormBoxVue :title="'Company Name'" :isRequired="false">
                <template #content>
                    <el-input v-model="formData.companyName" placeholder=""></el-input>
                </template>
            </FormBoxVue>

            <FormBoxVue :title="'Work Email'" :isRequired="true">
                <template #content>
                    <el-input v-model="formData.workEmail" placeholder=""></el-input>
                </template>
            </FormBoxVue>

            <FormBoxVue :title="'Phone'" :isRequired="true">
                <template #content>
                    <el-input v-model="formData.phone" placeholder=""></el-input>
                </template>
            </FormBoxVue>

            <FormBoxVue :title="'Country'" :isRequired="false">
                <template #content>
                    <el-input v-model="formData.country" placeholder=""></el-input>
                </template>
            </FormBoxVue>

            <FormBoxVue :title="'Your Message'" :isRequired="false">
                <template #content>
                    <el-input v-model="formData.message" type="textarea" placeholder=""></el-input>
                </template>
            </FormBoxVue>
        </div>

        <div slot="footer" class="dialog_footer">
            <el-button type="primary" @click="onDialogSubmit">Submit</el-button>
            <el-button @click="dialogVisible = false">Cancel</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { leaveMessage } from "@/api/widget/index"
import FormBoxVue from "@/views/widget/components/formBox.vue"

export default {
    components: {
        FormBoxVue
    },
    data() {
        return {
            width: "40%",
            formData: {
                firstName: "",
                lastName: "",
                companyName: "",
                workEmail: "",
                phone: "",
                country: "",
                message: ""
            },
            dialogVisible: false
        }
    },
    methods: {
        onHandleClose() {
            this.dialogVisible = false
        },
        onDialogSubmit() {
            if (this.validateForm()) {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                leaveMessage(this.formData).then(res => {
                    loading.close();
                    this.$message.success('Submit successfully');
                    this.dialogVisible = false
                }).catch(err => {
                    this.$message.error(err || 'Unknown error')
                }).finally(() => {
                    loading.close()
                    this.clearFormData()
                })
            }
        },
        openDialog(row) {
            this.dialogVisible = true
        },
        validateForm() {
            if (this.formData.firstName == '' || this.formData.lastName == '' || this.formData.workEmail == '' || this.formData.phone == '') {
                this.$message.warning("Please fill in the form information")

                return false
            }
            return true
        },
        clearFormData() {
            for (let key in this.formData) {
                this.formData[key] = "";
            }
        },
        updatePagerCount() {
            const width = window.innerWidth;
            this.width = width < 768 ? '360px' : '40%';
        },
    },
    mounted() {
        this.updatePagerCount();
        window.addEventListener('resize', this.updatePagerCount);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updatePagerCount);
    },
}
</script>

<style lang='scss'>
.apply-membership-dialog {
    .el-dialog__body {
        padding: 10px 30px;
    }
}
</style>